<template>
  <div id="order">
    <v-flex xs12 :class="isMobile? 'mt-2' : 'mt-0'">
      <h1 v-if="!isMobile">{{ $t('order.title')}}</h1>
      <p v-if="!isMobile" class="text">{{ $t('order.subtitle')}}</p>

      <v-divider v-if="!isMobile" class="mt-8 mb-4"></v-divider>

      <v-flex xs12 sm12 md12 v-if="order.length === 0">
        <p class="text"> {{ $t('order.no-data')}} </p>
      </v-flex>

      <v-flex xs12 sm12 md12  :class="isMobile ? 'mb-2' : 'mt-4'" v-for="(item, index) in order" :key="index">
        <v-layout wrap :class="isMobile ? 'item bordered' : 'pl-0'">
          <v-flex xs10 v-if="!isMobile">
            <div class="detail-item">
              <span class="title uppercase"> {{ $t('order.order')}} {{ item.sales_order_number }}</span> -
              <span class="italic"> {{ item.status_message }} </span>
              <p class="subtitle" v-if="item.no_resi"> {{ $t('order.resi-number')}} {{item.no_resi}}</p>
              <p class="subtitle"> {{ formatDate(item.order_date) }} - {{ addComma(item.total) }} IDR</p>
            </div>
          </v-flex>
          <v-flex xs8 sm8 v-else @click="goToDetail(item.id_sales_header)">
            <div class="detail-item">
              <span class="title"> {{ item.status_message }} </span>
              <p class="subtitle"><b>{{ addComma(item.total) }} IDR</b></p>
              <p class="subtitle-gray"> {{ $t('order.order')}} {{ item.sales_order_number }}</p>
              <p class="subtitle-gray" v-if="item.no_resi"> {{ $t('order.resi-number')}} {{item.no_resi}}</p>
              <p class="subtitle-gray"> {{ formatDate(item.order_date) }} </p>
            </div>
          </v-flex>
          <v-flex xs2 v-if="!isMobile">
            <v-btn v-if="item.no_resi" @click="tracking(item.no_resi)"
              tile
              color="black"
              dark
              width="100%"
              height="44px"
              elevation="1"
            >
              <span class="btn-text uppercase"> {{ $t('order.tracking')}} </span>
            </v-btn>
            <v-btn tile color="white" width="100%" height="44px" elevation="1" @click="goToDetail(item.id_sales_header)">
              <span class="btn-text">  {{ $t('order.see-detail')}} </span>
            </v-btn>
          </v-flex>
        </v-layout>

        <v-divider v-if="!isMobile" class="mt-8"></v-divider>
      </v-flex>

    </v-flex>
    <v-dialog v-model="dialog" max-width="500px">
      <Tracking-order :no_resi="selectedResi" @close="close()" />
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
  import TrackingOrder from '@/components/TrackingOrder'

  export default {
    name: 'Order',
    components: { TrackingOrder },
    computed: {
      ...mapState([
        'isMobile',
        'isLogin',
        'token'
      ])
    },
    data () {
      return {
        order: [],
        selectedResi: null,
        dialog: false
      }
    },
    methods: {
      tracking (data) {
        this.selectedResi = data
        setTimeout(() => { 
          this.dialog = true
        }, 200)
      },
      close () {
        this.dialog = false
      },
      goToDetail (id) {
        this.$router.push({ name:'Order-Detail', params: { id: id }})
      },
      getHistory () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.get('/api/v1/member/order_history')
          .then(response => {
            this.order = response.data.data
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      }
    }, 
    mounted () {
      if (this.isLogin) {
        this.getHistory()
      }
      this.setMetaInfo(null, 'Purchase History')

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', this.$t('order.title'))
    }
  }
</script>

<style scoped>
  .item {
    padding: 10px;
  }

  .detail-item {
    color: #000000;
  }

  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
    font-style: normal !important;
  }

  .title {
    color: #000000;
    font-weight: 900;
    font-size: 0.9rem !important;
  }

  .subtitle {
    color: #000000;
    font-size: 0.8rem !important;
  }

  .subtitle-gray {
    color: #808080;
    margin-bottom: 0;
    font-size: 0.8rem !important;
  }

  .img {
    position: relative;
    top: 20px;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .italic {
    font-style: italic;
    font-weight: normal;
  }

  .bordered {
    border: 1px solid #000000;
  }

  .btn-text {
    font-size: 0.6rem;
    font-weight: bold;
  }

  .uppercase {
    text-transform: uppercase;
  }

</style>
